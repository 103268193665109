import InputTextFloating from '../../components/input/textFloating';

const DetailItem = ({
  type,
  value,
  onChange,
  label,
  onKeyUp,
  autoFocus = true,
}) => {
  return (
    <div className="mb-12">
      <InputTextFloating
        type={type}
        inputProps={{
          value,
          onChange,
          onKeyUp,
          autoFocus,
        }}
        label={label}
      />
    </div>
  );
};

export default DetailItem;
