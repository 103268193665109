import { USER_TYPE, SIGNUP_STEP } from './enum';

const UserTypeForm = ({ formData, setFormData, nextStep }) => {
  const handler = (value) => {
    setFormData({ ...formData, userType: value });
    nextStep(SIGNUP_STEP.CONSENT);
  };
  return (
    <div className='p-4'>
      <h1 className='text-xl font-bold mb-4'>
        소속을
        <br />
        알려주세요
      </h1>
      <div className='text-lg font-bold flex flex-col gap-4'>
        <div
          className='cursor-pointer'
          onClick={() => handler(USER_TYPE.OWNER_PHARMACIST)}
        >
          🧑🏻‍⚕️ 개국약사
        </div>
        <div
          className='cursor-pointer'
          onClick={() => handler(USER_TYPE.SALES)}
        >
          🧑🏻‍💼 제약사 직원
        </div>
        {/* <div
          className='cursor-pointer'
          onClick={() => handler(USER_TYPE.STUDENT)}
        >
          🧑🏻‍🏫 약대생
        </div>
        <div
          className='cursor-pointer'
          onClick={() => handler(USER_TYPE.PAY_PHARMACIST)}
        >
          🧑🏻‍🎓 근무 약사
        </div> */}
      </div>
    </div>
  );
};

export default UserTypeForm;
