const numToStr = (num) => {
  return num < 10 ? `0${num}` : `${num}`;
};

export const presetDateRange = (preset) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const date = today.getDate();

  if (preset === 'oneMonth') {
    const oneMonthBefore = `${year}-${numToStr(month)}-${numToStr(date)}`;
    const currentMonth = `${year}-${numToStr(month + 1)}-${numToStr(date)}`;
    return [oneMonthBefore, currentMonth];
  }
  if (preset === 'threeMonth') {
    const oneMonthBefore = `${year}-${numToStr(month - 2)}-${numToStr(date)}`;
    const currentMonth = `${year}-${numToStr(month + 1)}-${numToStr(date)}`;
    return [oneMonthBefore, currentMonth];
  }
  if (preset === 'lastMonth') {
    const lastMonthStart = `${year}-${numToStr(month)}-01`;

    const lastMonthEndDate = new Date(year, month, 0);
    const lastMonthEnd = `${year}-${numToStr(
      month
    )}-${lastMonthEndDate.getDate()}`;
    return [lastMonthStart, lastMonthEnd];
  }
  if (preset === 'customMonth') {
    // const oneMonthBefore = `${year}-${numToStr(month)}-${numToStr(date)}`;
    const currentMonth = `${year}-${numToStr(month + 1)}-${numToStr(date)}`;
    return ['2020-01-01', currentMonth];
  }
};
