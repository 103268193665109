import { useEffect } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import defaultProfile from '../../assets/png/profile.png';
import { ReactComponent as TransactionSvg } from '../../assets/svg/transaction.svg';
import { ReactComponent as NoticeSvg } from '../../assets/svg/notice.svg';
import { ReactComponent as CustomerCenterSvg } from '../../assets/svg/customer-center.svg';
import { ReactComponent as ArrowRightSvg } from '../../assets/svg/arrow-right.svg';
import { ReactComponent as SellerSvg } from '../../assets/svg/seller.svg';

const MyPageNavPage = () => {
  const { loading, user } = useOutletContext();

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/main';
    }
  }, [user, loading]);

  return (
    <div className='py-8 px-8'>
      <header className='flex justify-between items-center mb-8'>
        <div className='flex items-center gap-4'>
          <div className='w-16 h-16 bg-gray-300 rounded-full flex items-center justify-center'>
            <img
              src={defaultProfile}
              alt='Profile'
            />
          </div>
          <div>
            <h2 className='text-xl font-bold mb-1'>{user?.name}</h2>
            <p className='text-gray-500 text-sm'>
              {user && user.pharmacies.map((pharm) => pharm.name).join(' / ')}
            </p>
          </div>
        </div>
        <button
          className='border border-[#616161] px-2 py-1 rounded'
          onClick={() => {
            window.location.href = '/mypage/setting';
          }}
        >
          계정관리
        </button>
      </header>
      <section className='mb-8'>
        <h3 className='text-[#BFBFBF] mb-4 font-bold'>결제</h3>
        <ul className='divide-y divide-solid'>
          <li className='py-6'>
            <Link
              to='transaction'
              className='flex justify-between items-center'
            >
              <div className='flex items-center gap-3 font-bold'>
                <TransactionSvg />
                <div>거래내역</div>
              </div>
              <ArrowRightSvg />
            </Link>
          </li>
          <li className='py-6'>
            <Link
              to='seller/management'
              className='flex justify-between items-center'
            >
              <div className='flex items-center gap-3 font-bold'>
                <SellerSvg />
                <div>직거래처 관리</div>
              </div>
              <ArrowRightSvg />
            </Link>
          </li>
        </ul>
      </section>

      <section className='mb-8'>
        <h3 className='text-[#BFBFBF] mb-4 font-bold'>기타</h3>
        <ul className='divide-y divide-solid divide-gray-100'>
          <li className='py-8'>
            <div
              to='notice'
              className='flex justify-between items-center'
            >
              <div className='flex items-center gap-3 font-bold'>
                <NoticeSvg />
                <div>공지사항</div>
              </div>
              <ArrowRightSvg />
            </div>
          </li>
          <li className='py-8'>
            <div
              to='cs'
              className='flex justify-between items-center'
            >
              <div className='flex items-center gap-3 font-bold'>
                <CustomerCenterSvg />
                <div>고객센터</div>
              </div>
              <ArrowRightSvg />
            </div>
          </li>
          <li className='py-8'>
            <div
              to='policy'
              className='flex justify-between items-center'
            >
              <div className='font-bold'>약관 및 개인정보 활용</div>
              <ArrowRightSvg />
            </div>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default MyPageNavPage;
