import React from 'react';

const SearchTabAll = () => {
  return (
    <div>
      <div className='p-4 text-sm'>최근 검색어</div>
      <div className='p-4 text-sm'>많이 찾는 검색어</div>
    </div>
  );
};

export default SearchTabAll;
