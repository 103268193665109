const getStatusName = (status) => {
  if (status === 'REFUND') {
    return (
      <div className='bg-red-100 px-2.5 py-1 text-red-500 font-bold rounded w-10.5 h-10 flex items-center justify-center'>
        반품
      </div>
    );
  }
  if (status === 'BUY') {
    return (
      <div className='bg-blue-100 px-2.5 py-1 text-blue-500 font-bold rounded w-10.5 h-10 flex items-center justify-center'>
        사입
      </div>
    );
  }
  if (status === 'PAY') {
    return (
      <div className='bg-green-100 px-2.5 py-1 text-green-500 font-bold rounded w-10.5 h-10 flex items-center justify-center'>
        결제
      </div>
    );
  }
};

const TransactionItem = ({ date, status, name, company, price }) => {
  return (
    <div className={`text-xs py-3 ${status !== 'PAY' ? 'pl-6' : ''}`}>
      {status === 'PAY' && <div className='text-gray-500 mb-2'>{date}</div>}
      <div className='flex justify-between'>
        <div className='flex items-center gap-2'>
          {getStatusName(status)}
          <div>
            <div className='font-bold'>{name}</div>
            <div className=''>{company}</div>
          </div>
        </div>
        <div className='text-right'>
          <div className='font-bold'>{price && price.toLocaleString()}원</div>
        </div>
      </div>
    </div>
  );
};

export default TransactionItem;
