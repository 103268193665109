import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CapsuleIcon } from '../../assets/svg/capsule.svg';
import { ReactComponent as MessageIcon } from '../../assets/svg/message.svg';
import { ReactComponent as CallbookIcon } from '../../assets/svg/callbook.svg';
import { ReactComponent as PharmIcon } from '../../assets/svg/pharm.svg';
import { ReactComponent as CapuleFilledIcon } from '../../assets/svg/capsule_filled.svg';
import { ReactComponent as MessageFilledIcon } from '../../assets/svg/message_filled.svg';
import { ReactComponent as CallbookFilledIcon } from '../../assets/svg/callbook_filled.svg';
import { ReactComponent as PharmFilledIcon } from '../../assets/svg/pharm_filled.svg';

const navList = [
  {
    path: '/',
    stack: 'search',
    label: '의약품 검색',
    defaultIcon: <CapsuleIcon />,
    activeIcon: <CapuleFilledIcon />,
  },
  {
    path: '/messenger',
    stack: 'messenger',
    label: '메신저',
    defaultIcon: <MessageIcon />,
    activeIcon: <MessageFilledIcon />,
  },
  {
    path: '/contacts',
    stack: 'contacts',
    label: '연락처',
    defaultIcon: <CallbookIcon />,
    activeIcon: <CallbookFilledIcon />,
  },
  {
    path: '/mypage',
    stack: 'mypage',
    label: '마이페이지',
    defaultIcon: <PharmIcon />,
    activeIcon: <PharmFilledIcon />,
  },
];

const NavBottom = () => {
  const location = useLocation();

  return (
    <nav className='fixed bottom-0 left-0 right-0 bg-white border-t border-gray-100 py-1'>
      <ul className='flex items-center'>
        {navList.map((nav) => (
          <li
            key={nav.path}
            className='flex-1'
          >
            <Link
              to={nav.path}
              className='flex flex-col items-center py-1'
            >
              {(location.pathname === nav.path && nav.stack === 'search') ||
              location.pathname.includes(nav.stack)
                ? nav.activeIcon
                : nav.defaultIcon}
              <span
                className={`text-[10px] mt-px font-semibold ${
                  (location.pathname === nav.path && nav.stack === 'search') ||
                  location.pathname.includes(nav.stack)
                    ? 'text-[#002060]'
                    : 'text-[#D9D9D9]'
                }`}
              >
                {nav.label}
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBottom;
