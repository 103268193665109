import { Outlet } from 'react-router-dom';
import useAuth from '../hook/useAuth';

const MainLayout = () => {
  const { user, signIn, signOut } = useAuth();

  return (
    <div className='max-w-lg mx-auto'>
      <Outlet context={{ user, signIn, signOut }} />
    </div>
  );
};

export default MainLayout;
