import defaultProfile from '../../assets/png/profile.png';

const ContactItem = ({ contact }) => {
  return (
    <div className='flex items-center justify-between p-4 bg-white rounded-lg shadow'>
      <div className='flex items-center'>
        <img
          src={contact.avatar}
          alt='Avatar'
          className='w-12 h-12 rounded-full mr-3'
        />
        <div>
          <div className='text-sm font-bold'>
            {contact.company}{' '}
            <span className='text-gray-500'>{contact.name}</span>
          </div>
          {contact.status ? (
            <div className='text-sm text-green-500'>{contact.status}</div>
          ) : (
            <div className='text-sm text-gray-500'>
              거래내역 {contact.transactions}
            </div>
          )}
        </div>
      </div>
      <div className='flex items-center space-x-2'>
        <button className='p-2 bg-blue-500 text-white rounded-full'>📞</button>
        <button className='p-2 bg-blue-500 text-white rounded-full'>💬</button>
      </div>
    </div>
  );
};

const ContactsNavPage = () => {
  const approvedContacts = [
    {
      id: 1,
      name: '신정아 과장',
      company: '조아제약',
      status: '직거래처 승인 대기중',
      avatar: defaultProfile,
    },
  ];

  const contacts = [
    {
      id: 2,
      name: '신정아 과장',
      company: '조아제약',
      transactions: 3,
      avatar: defaultProfile,
    },
    {
      id: 3,
      name: '신정아 과장',
      company: '조아제약',
      transactions: 2,
      avatar: defaultProfile,
    },
  ];

  return (
    <div className='p-4'>
      <header className='flex justify-between items-center mb-4'>
        <h1 className='text-xl font-bold'>제약사 연락처</h1>
        <button className='p-2 rounded-full bg-blue-500 text-white'>+</button>
      </header>
      <div className='bg-blue-100 p-2 rounded mb-4 flex items-center justify-between'>
        <div className='flex items-center'>
          <span
            role='img'
            aria-label='bell'
            className='mr-2'
          >
            🔔
          </span>
          <span>N건의 담당자 등록/변경 요청이 있습니다</span>
        </div>
        <button className='text-blue-500'>&gt;</button>
      </div>
      <section className='mb-8'>
        <h2 className='text-gray-400 mb-2'>승인된 연락처</h2>
        {approvedContacts.map((contact) => (
          <ContactItem
            key={contact.id}
            contact={contact}
          />
        ))}
      </section>
      <section>
        <h2 className='text-gray-400 mb-2'>연락 가능한 제약사</h2>
        <div className='relative mb-4'>
          <input
            type='text'
            placeholder='연락처 검색'
            className='w-full p-2 border rounded'
          />
          <span className='absolute right-3 top-3'>🔍</span>
        </div>
        <div className='space-y-4'>
          {contacts.map((contact) => (
            <ContactItem
              key={contact.id}
              contact={contact}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default ContactsNavPage;
