import { useState } from 'react';

const DETAIL_TAB = {
  BASIC: 'BASIC',
  DETAIL: 'DETAIL',
  TRADE: 'TRADE',
};

const SearchDetail = ({ itemDetail }) => {
  const [detailTab, setDetailTab] = useState(DETAIL_TAB.BASIC);

  const handleDetailTab = (tab) => () => {
    setDetailTab(tab);
  };

  return (
    <div className='max-h-[calc(100svh-54px)] overflow-auto pb-12'>
      <div className='py-3 px-2 mb-4 text-sm'>
        <div className='mb-4 flex justify-center'>
          <img
            src={itemDetail.thumbnail}
            alt='thumbnail'
            className='w-2/3'
          />
        </div>
        <div>{itemDetail.company}</div>
        <div className='text-md font-bold'>{itemDetail.itemName}</div>
        <div>
          {itemDetail.materials.map((v) => (
            <span
              key={v}
              className='mr-1'
            >
              {v}
            </span>
          ))}
        </div>
      </div>
      <ul className='flex text-center text-stone-400 mb-6 font-bold'>
        <li
          className={`border-b flex-1 pb-2 ${
            detailTab === DETAIL_TAB.BASIC
              ? 'border-black text-black'
              : 'border-stone-400'
          }`}
          onClick={handleDetailTab(DETAIL_TAB.BASIC)}
        >
          기본정보
        </li>
        <li
          className={`border-b flex-1 pb-2 ${
            detailTab === DETAIL_TAB.DETAIL
              ? 'border-black text-black'
              : 'border-stone-400'
          }`}
          onClick={handleDetailTab(DETAIL_TAB.DETAIL)}
        >
          상세정보
        </li>
        <li
          className={`border-b flex-1 pb-2 ${
            detailTab === DETAIL_TAB.TRADE
              ? 'border-black text-black'
              : 'border-stone-400'
          }`}
          onClick={handleDetailTab(DETAIL_TAB.TRADE)}
        >
          직거래관
        </li>
      </ul>
      {detailTab === DETAIL_TAB.BASIC && (
        <div className='px-6 flex flex-col gap-6'>
          <div>
            <div className='font-bold mb-1'>구분</div>
            <div>{itemDetail.type}</div>
          </div>
          <div>
            <div className='font-bold mb-1'>식약처분류</div>
            <div>{itemDetail.class}</div>
          </div>
          {itemDetail.materialsUnits.length > 0 && (
            <div>
              <div className='font-bold mb-1'>성분/함량</div>
              <div>
                {itemDetail.materialsUnits.map((m) => (
                  <span
                    key={m.name}
                    className='mr-1'
                  >{`${m.name} ${m.volume}${m.unit}`}</span>
                ))}
              </div>
            </div>
          )}
          {itemDetail.packagesUnits.length > 0 && (
            <div>
              <div className='font-bold mb-1'>포장단위</div>
              <div>
                {itemDetail.packagesUnits.map((p) => (
                  <span
                    key={p.package}
                    className='mr-1'
                  >
                    {p.package}
                  </span>
                ))}
              </div>
            </div>
          )}
          <div>
            <div className='font-bold'>업체명</div>
            <div>{itemDetail.company}</div>
          </div>
        </div>
      )}
      {detailTab === DETAIL_TAB.DETAIL && (
        <div className='px-6 flex flex-col gap-6'>
          <div>
            <div className='font-bold mb-1'>효능효과</div>
            <div>{itemDetail.class}</div>
          </div>
          <div>
            <div className='font-bold'>용법용량</div>
            <div>{itemDetail.method}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchDetail;
