import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from '../../assets/svg/arrow_left.svg';

const PageHeader = ({ title, backPath }) => {
  return (
    <header className='flex gap-2 items-center px-2 py-3 justify-between'>
      <Link to={backPath}>
        <ChevronLeft />
      </Link>
      <div className='font-bold py-1'>{title}</div>
      <div />
    </header>
  );
};

export default PageHeader;
