import { useState } from 'react';
import {
  validatePharmacyName,
  validateAddress,
  validateHIRA,
  validatePharmacistRegNo,
  validateRegistrationImg,
} from './validation/pharmacy';
import request from '../../lib/request';
import DetailItem from './detailItem';
import PharmacyImgUpload from './pharmacyImgUpload';

export const PHARMACY_STEP = {
  PHARMACY_NAME: 'PHARMACY_NAME',
  ADDRESS: 'ADDRESS',
  HIRA_REG_NO: 'HIRA_REG_NO',
  PHARMACIST_REG_NO: 'PHARMACIST_REG_NO',
  REGISTRATION_IMG: 'REGISTRATION_IMG',
};

const PharmacyForm = ({ formData, setFormData }) => {
  const [detailSteps, setDetailSteps] = useState([]);
  const [heading, setHeading] = useState('약국 이름을 알려주세요');

  const nextDetailSteps = () => {
    const filledIn = detailSteps.slice();
    if (validateRegistrationImg(detailSteps, formData)) {
      filledIn.push(PHARMACY_STEP.REGISTRATION_IMG);
    }
    if (validatePharmacistRegNo(detailSteps, formData)) {
      filledIn.push(PHARMACY_STEP.PHARMACIST_REG_NO);
      setHeading('이미지 서류 제출이 필요해요');
    }
    if (validateHIRA(detailSteps, formData)) {
      filledIn.push(PHARMACY_STEP.HIRA_REG_NO);
      setHeading('약사면허번호를 입력해주세요');
    }
    if (validateAddress(detailSteps, formData)) {
      filledIn.push(PHARMACY_STEP.ADDRESS);
      setHeading('요양기관 번호를 입력해주세요');
    }
    if (validatePharmacyName(detailSteps, formData)) {
      filledIn.push(PHARMACY_STEP.PHARMACY_NAME);
      setHeading('약국 주소를 입력해주세요');
    }
    setDetailSteps(filledIn);
  };

  const handleOnChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: String(e.target.value),
    });
  };

  const handleEnter = (key) => (e) => {
    if (e.key === 'Enter') {
      handleOnChange(key)(e);
      nextDetailSteps();
    }
  };

  const handleSubmit = async () => {
    console.log('Pharmacy formData: ', formData);

    try {
      const { result } = await request.post('/signup', {
        ...formData,
        step: 'PHARMACY',
      });

      if (result?.id) {
        window.location.href = '/main';
      }
    } catch (error) {
      console.log('An error occurred', error);
    }
  };

  return (
    <div
      className={`flex flex-col justify-between h-full ${
        detailSteps.includes(PHARMACY_STEP.REGISTRATION_IMG)
          ? 'backdrop-brightness-50 overflow-hidden'
          : 'overflow-y-scroll'
      }`}
    >
      <div className="p-4">
        <h1 className="text-xl font-bold mb-12">{heading}</h1>
        {detailSteps.includes(PHARMACY_STEP.PHARMACIST_REG_NO) && (
          <PharmacyImgUpload formData={formData} setFormData={setFormData} />
        )}
        {detailSteps.includes(PHARMACY_STEP.HIRA_REG_NO) && (
          <DetailItem
            type="text"
            value={formData.pharmacistRegNo}
            onChange={handleOnChange('pharmacistRegNo')}
            onKeyUp={handleEnter('pharmacistRegNo')}
            label="약사 면허 번호"
          />
        )}
        {detailSteps.includes(PHARMACY_STEP.ADDRESS) && (
          <DetailItem
            type="text"
            value={formData.hiraRegNo}
            onChange={handleOnChange('hiraRegNo')}
            onKeyUp={handleEnter('hiraRegNo')}
            label="요양기관 번호"
          />
        )}
        {detailSteps.includes(PHARMACY_STEP.PHARMACY_NAME) && (
          <>
            <DetailItem
              type="text"
              value={formData.address}
              onChange={handleOnChange('address')}
              label="기본주소"
            />
            <DetailItem
              type="text"
              value={formData.addressDetail}
              onChange={handleOnChange('addressDetail')}
              onKeyUp={handleEnter('addressDetail')}
              label="상세주소 및 건물명"
              autoFocus={false}
            />
          </>
        )}
        <DetailItem
          value={formData.pharmacyName}
          onChange={handleOnChange('pharmacyName')}
          onKeyUp={handleEnter('pharmacyName')}
          label="약국이름"
        />
      </div>
      {detailSteps.includes(PHARMACY_STEP.REGISTRATION_IMG) && (
        <div className="absolute bottom-0 top-0 right-0 left-0 bg-white">
          <div className="h-full flex justify-center items-center text-center">
            <div>
              <p className="text-5xl">🚦</p>
              <p className="text-xl font-bold">
                가입이 완료되면
                <br />
                문자로 알려드릴게요.
              </p>
              <p>
                개국 약사님 확인을 위해서
                <br />
                팜챗팀의 승인절차가 이루어집니다.
                <br />
                영업일 1-2일내로 알려드릴게요.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="absolute bottom-0 w-full">
        <button
          onClick={
            detailSteps.includes(PHARMACY_STEP.REGISTRATION_IMG)
              ? handleSubmit
              : nextDetailSteps
          }
          className="w-full py-2 mt-4 rounded text-white bg-[#002060]"
        >
          {detailSteps.includes(PHARMACY_STEP.REGISTRATION_IMG)
            ? '네, 확인했어요'
            : '다음'}
        </button>
      </div>
    </div>
  );
};

export default PharmacyForm;
