import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import sampleImg from '../../assets/png/image_sample.png';
import NavHeader from '../../components/nav/header';

const ProductCard = () => {
  return (
    <div className='border rounded-lg p-4 w-40'>
      <img
        src={sampleImg}
        alt='Product'
        className='mb-2'
      />
      <h3 className='text-sm font-bold'>스피드 연질</h3>
      <p className='text-sm text-gray-500'>1,300원</p>
      <div className='flex justify-between items-center mt-2'>
        <span className='text-xs text-red-500'>40% 할인</span>
        <button className='p-1'>
          <span
            role='img'
            aria-label='heart'
          >
            ❤️
          </span>
        </button>
      </div>
    </div>
  );
};

const MainNavPage = () => {
  const { loading, user } = useOutletContext();

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/main';
    }
  }, [user, loading]);

  return (
    <>
      <NavHeader />
      <div className='p-4'>
        <section className='mb-8'>
          <h2 className='text-lg font-bold text-red-500'>
            {user && user.pharmacies.map((pharm) => pharm.name).join(' / ')}
          </h2>
          <p>맞춤형 제품을 모아봤어요.</p>
          <div className='flex space-x-4 overflow-x-scroll'>
            <ProductCard />
            <ProductCard />
            <ProductCard />
          </div>
        </section>

        <section className='mb-8'>
          <h2 className='text-lg font-bold text-orange-500'>
            프로모션 중인 제품들은 팜챗에서만 만날 수 있어요!
          </h2>
          <div className='flex space-x-4 overflow-x-scroll'>
            <ProductCard />
            <ProductCard />
            <ProductCard />
          </div>
        </section>

        <section>
          <h2 className='text-lg font-bold'>따끈따끈한 신제품이에요</h2>
          <div className='space-y-4'>
            <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard />
            <ProductCard />
          </div>
        </section>
      </div>
    </>
  );
};

export default MainNavPage;
