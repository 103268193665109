import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './layout';
import MainLayout from './layout/main';
import SearchPage from './page/search';
import EntrancePage from './page/entrance';
import SignInPage from './page/signin';
import SignUpPage from './page/signup';
import Notification from './page/notification';
import ErrorPage from './page/error';
import MainNavPage from './navPage/main';
import MessengerNavPage from './navPage/messenger';
import ContactsNavPage from './navPage/contacts';
import MyPageNavPage from './navPage/mypage';
import AccountManagement from './page/mypage/accountManagement';
import Transaction from './page/mypage/transaction';
import SellerManagement from './page/mypage/sellerManagement';

const router = createBrowserRouter([
  {
    path: '',
    errorElement: <ErrorPage />,
    Component: Layout,
    children: [
      {
        path: '',
        element: <MainNavPage />,
      },
      {
        path: 'messenger',
        element: <MessengerNavPage />,
      },
      {
        path: 'contacts',
        element: <ContactsNavPage />,
      },
      {
        path: 'mypage',
        element: <MyPageNavPage />,
      },
      {
        path: 'mypage/setting',
        element: <AccountManagement />,
      },
      {
        path: 'mypage/transaction',
        element: <Transaction />,
      },
      {
        path: 'mypage/seller/management',
        element: <SellerManagement />,
      },
      {
        path: 'search',
        element: <SearchPage />,
      },
      {
        path: 'notification',
        element: <Notification />,
      },
    ],
  },
  {
    path: 'main',
    Component: MainLayout,
    children: [
      {
        path: '',
        element: <EntrancePage />,
      },
      {
        path: 'signin',
        element: <SignInPage />,
      },
      {
        path: 'signup',
        element: <SignUpPage />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
