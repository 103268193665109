import { useState, useEffect } from 'react';
import request from '../lib/request';

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (typeof window === 'object') {
      requestUser();
    }
  }, []);

  const requestUser = async () => {
    setLoading(true);
    try {
      const { result } = await request.get('/user/info');
      console.log('user info: ', result);
      if (!result) {
        setUser({});
        throw new Error('Invalid user');
      }

      setUser(result);
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  };

  const signIn = async (email, password) => {
    setLoading(true);
    if (email && password) {
      try {
        const { result } = await request.post('/signin', {
          email,
          password,
        });
        if (result?.token) {
          window.localStorage.setItem('_accessToken', result.token);
          request.setToken(result.token);
          window.location.href = '/';
        }
      } catch (err) {
        console.log(err.message);
      }
    }
    setLoading(false);
  };

  const signOut = async () => {
    setUser(null);
    window.localStorage.removeItem('_accessToken');
    window.location.href = '/main';
  };

  return { loading, user, signIn, signOut };
};

export default useAuth;
