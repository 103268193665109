import { useState } from 'react';
import { USER_TYPE, SIGNUP_STEP } from './enum';
import { ReactComponent as ChevronLeft } from '../../assets/svg/arrow_left.svg';
import ConsentForm from './consentForm';
import UserTypeForm from './userTypeForm';
import PharmacistDetailForm from './pharmacistForm/detailForm';
import SalesDetailForm from './salesForm/detailForm';
import PharmacyForm from './pharmacyForm';

const SignUpPage = () => {
  const [step, setStep] = useState(SIGNUP_STEP.USER_TYPE);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    password: '',
    rePassword: '',
    verificationCode: '',
    userType: '',
    company: '',
    job: '',
    department: '',
    rank: '',
    bizCardImg: undefined,
  });
  const [pharmacy, setPharmacy] = useState({
    userId: '',
    pharmacyName: '',
    address: '',
    addressDetail: '',
    hiraRegNo: '',
    pharmacistRegNo: '',
    pharmacyRegImg: undefined,
    pharmacistLicenseImg: undefined,
    bizRegImg: undefined,
  });

  const nextStep = (key) => setStep(key);

  const renderStep = () => {
    switch (step) {
      case SIGNUP_STEP.USER_TYPE:
        return (
          <UserTypeForm
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
          />
        );
      case SIGNUP_STEP.CONSENT:
        return (
          <ConsentForm
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
          />
        );
      case SIGNUP_STEP.DETAIL:
        if (formData.userType === USER_TYPE.OWNER_PHARMACIST) {
          return (
            <PharmacistDetailForm
              formData={formData}
              setFormData={setFormData}
              setPharmacy={setPharmacy}
              nextStep={nextStep}
            />
          );
        }
        if (formData.userType === USER_TYPE.SALES) {
          return (
            <SalesDetailForm
              formData={formData}
              setFormData={setFormData}
              setPharmacy={setPharmacy}
              nextStep={nextStep}
            />
          );
        }
        return null;
      case SIGNUP_STEP.PHARMACY:
        return (
          <PharmacyForm
            formData={pharmacy}
            setFormData={setPharmacy}
            nextStep={nextStep}
          />
        );
      default:
        return (
          <UserTypeForm
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
          />
        );
    }
  };

  return (
    <div className='flex flex-col h-svh justify-between relative'>
      <div className='relative text-center py-3'>
        <div className='font-bold'>회원가입</div>
        <div
          className='absolute top-3 left-4'
          onClick={() => {
            window.location.href = '/main';
          }}
        >
          <ChevronLeft />
        </div>
      </div>
      <div className='h-full overflow-hidden'>{renderStep()}</div>
    </div>
  );
};

export default SignUpPage;
