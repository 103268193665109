import { useEffect, useState } from 'react';
import { ReactComponent as DeleteSvg } from '../../../assets/svg/delete.svg';
import InputTextFloating from '../../../components/input/textFloating';
import PageHeader from '../../../components/page/header';
import request from '../../../lib/request';

const MallIntegration = ({
  handleOpenIntegrationModal,
  selectedIntegrationCompany,
  account,
  handleFormData,
  handleSaveAccount,
}) => {
  return (
    <>
      <header className='flex gap-2 items-center px-2 py-3 justify-between'>
        <div
          onClick={handleOpenIntegrationModal}
          className='cursor-pointer'
        >
          <DeleteSvg />
        </div>
        <div className='font-bold py-1'>몰연동</div>
        <div />
      </header>
      <div className='flex flex-col justify-between h-[calc(100%-114px)] overflow-y-auto'>
        <div className='px-4'>
          <div className='space-y-2 mb-6'>
            <div className='text-lg font-bold'>
              {selectedIntegrationCompany.name}
            </div>
            <p className='text-xs'>
              제약사 온라인몰에 로그인하시는 계정 정보를 입력해주세요.
            </p>
          </div>
          <div className='space-y-8'>
            <InputTextFloating
              label='아이디'
              inputProps={{
                value: account.id,
                onChange: handleFormData('id'),
              }}
            />
            <InputTextFloating
              type='password'
              label='비밀번호'
              inputProps={{
                value: account.password,
                onChange: handleFormData('password'),
              }}
            />
          </div>
          <p className='text-xs mt-8'>
            *등록하신 계정 정보는 암호화 되어 안전하게 관리됩니다.
            <br />
            아이디 또는 비밀번호를 변경하신 경우, 상담톡으로 연락 부탁드립니다.
          </p>
        </div>
        <button
          className='w-full py-2 mt-4 bg-[#002060] text-white'
          onClick={handleSaveAccount}
        >
          로그인
        </button>
      </div>
    </>
  );
};

const CompanyCard = ({
  company,
  handleOpenIntegrationModal,
  handleSelectIntegrationCompany,
}) => {
  return (
    <div className='border-2 rounded-md p-4'>
      <div className='flex items-center gap-3 mb-6'>
        <div className='border rounded-md w-11 h-11 p-2'>
          <img
            src={company.logo}
            alt=''
          />
        </div>
        <div className='text-xl font-bold'>{company.name}</div>
      </div>
      <div className='space-y-3'>
        <div className='flex items-center justify-between'>
          <div>연락</div>
          <div className='text-[#29CB00]'>가능</div>
        </div>
        <div className='flex items-center justify-between'>
          <div>직거래</div>
          <div className='text-[#1A73E9]'>대기중</div>
        </div>
        <div className='flex items-center justify-between'>
          <div>몰연동</div>
          <div>
            {company.loginId ? (
              <span className='text-[#29CB00]'>연동됨</span>
            ) : (
              <button
                className='border border-black rounded-sm py-1 px-2'
                onClick={() => {
                  handleSelectIntegrationCompany(company);
                  handleOpenIntegrationModal();
                }}
              >
                연동
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SellerManagement = () => {
  const [companyList, setCompanyList] = useState([]);
  const [companyIntegratedList, setCompanyIntegratedList] = useState([]);
  const [openIntegrationModal, setOpenIntegrationModal] = useState(false);
  const [selectedIntegrationCompany, setSelectedIntegrationCompany] = useState(
    {}
  );
  const [account, setAccount] = useState({
    id: '',
    password: '',
  });

  useEffect(() => {
    getCompanyList();
  }, []);

  const getCompanyList = async () => {
    try {
      const { result } = await request.get('/company');
      const integratedList = [];
      const list = [];

      if (result) {
        for (const res of result) {
          if (res.loginId) {
            integratedList.push(res);
          } else {
            list.push(res);
          }
        }
        setCompanyList(list);
        setCompanyIntegratedList(integratedList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectIntegrationCompany = (company) => {
    setSelectedIntegrationCompany(company);
  };

  const handleOpenIntegrationModal = () => {
    setOpenIntegrationModal((prev) => !prev);
  };

  const handleFormData = (key) => (e) => {
    setAccount((prev) => ({
      ...prev,
      [key]: String(e.target.value),
    }));
  };

  const handleSaveAccount = async () => {
    try {
      await request.post('/user/company/account', {
        ...account,
        companyId: selectedIntegrationCompany.id,
      });
      handleSelectIntegrationCompany({});
      setAccount({
        id: '',
        password: '',
      });
      handleOpenIntegrationModal();
      await getCompanyList();
    } catch (err) {
      console.log(err);
    }
  };

  return openIntegrationModal ? (
    <div className='h-dvh overflow-hidden'>
      <MallIntegration
        handleOpenIntegrationModal={handleOpenIntegrationModal}
        selectedIntegrationCompany={selectedIntegrationCompany}
        account={account}
        handleFormData={handleFormData}
        handleSaveAccount={handleSaveAccount}
      />
    </div>
  ) : (
    <div className='h-dvh overflow-hidden'>
      <PageHeader
        title='직거래처 관리'
        backPath='/mypage'
      />
      <div className='px-4'>검색 섹션</div>
      <div className='h-[calc(100%-152px)] overflow-y-auto'>
        <section className='px-4 space-y-4'>
          <h2 className='text-gray-400 mb-2'>연동된 제약사</h2>
          {companyIntegratedList.map((company) => (
            <CompanyCard
              key={company.id}
              company={company}
              handleOpenIntegrationModal={handleOpenIntegrationModal}
              handleSelectIntegrationCompany={handleSelectIntegrationCompany}
            />
          ))}
        </section>
        <hr className='h-1 bg-gray-100 border-0 my-4' />
        <section className='px-4 space-y-4'>
          <h2 className='text-gray-400 mb-2'>미연동된 제약사</h2>
          {companyList.map((company) => (
            <CompanyCard
              key={company.id}
              company={company}
              handleOpenIntegrationModal={handleOpenIntegrationModal}
              handleSelectIntegrationCompany={handleSelectIntegrationCompany}
            />
          ))}
        </section>
      </div>
    </div>
  );
};

export default SellerManagement;
