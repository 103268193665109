import { useState } from 'react';
import { SIGNUP_STEP } from './enum';

const ConsentForm = ({ nextStep }) => {
  const [consents, setConsents] = useState({
    all: false,
    order: false,
    service: false,
    privacy: false,
    marketing: false,
  });

  const handleAllChange = () => {
    const newAll = !consents.all;
    setConsents({
      all: newAll,
      order: newAll,
      service: newAll,
      privacy: newAll,
      marketing: newAll,
    });
  };

  const handleConsentChange = (name) => {
    setConsents({
      ...consents,
      [name]: !consents[name],
      all: false,
    });
  };

  return (
    <div className='flex flex-col justify-between h-full'>
      <div className='p-4'>
        <h1 className='text-xl font-bold mb-4'>
          팜챗 서비스 이용약관에 동의해주세요.
        </h1>
        <div className='mb-4'>
          <label className='inline-flex items-center'>
            <input
              type='checkbox'
              checked={consents.all}
              onChange={handleAllChange}
              className='form-checkbox h-5 w-5 text-blue-600'
            />
            <span className='ml-2 text-gray-700'>
              아래 내용에 모두 동의합니다
            </span>
          </label>
        </div>
        <div className='mb-4'>
          <label className='inline-flex items-center'>
            <input
              type='checkbox'
              checked={consents.order}
              onChange={() => handleConsentChange('order')}
              className='form-checkbox h-5 w-5 text-blue-600'
            />
            <span className='ml-2 text-gray-700'>
              [필수] 팜챗을 통한 주문/결제대행에 동의
            </span>
            <button className='ml-2 text-blue-500'>보기</button>
          </label>
        </div>
        <div className='mb-4'>
          <label className='inline-flex items-center'>
            <input
              type='checkbox'
              checked={consents.service}
              onChange={() => handleConsentChange('service')}
              className='form-checkbox h-5 w-5 text-blue-600'
            />
            <span className='ml-2 text-gray-700'>
              [필수] 서비스 이용약관 동의
            </span>
            <button className='ml-2 text-blue-500'>보기</button>
          </label>
        </div>
        <div className='mb-4'>
          <label className='inline-flex items-center'>
            <input
              type='checkbox'
              checked={consents.privacy}
              onChange={() => handleConsentChange('privacy')}
              className='form-checkbox h-5 w-5 text-blue-600'
            />
            <span className='ml-2 text-gray-700'>
              [필수] 개인정보 수집 및 이용방침 동의
            </span>
            <button className='ml-2 text-blue-500'>보기</button>
          </label>
        </div>
        <div className='mb-4'>
          <label className='inline-flex items-center'>
            <input
              type='checkbox'
              checked={consents.marketing}
              onChange={() => handleConsentChange('marketing')}
              className='form-checkbox h-5 w-5 text-blue-600'
            />
            <span className='ml-2 text-gray-700'>
              [선택] 광고성 정보 수신 및 마케팅 활용 동의
            </span>
            <button className='ml-2 text-blue-500'>보기</button>
          </label>
        </div>
      </div>
      <div>
        <button
          onClick={() => nextStep(SIGNUP_STEP.DETAIL)}
          disabled={!consents.order || !consents.service || !consents.privacy}
          className={`w-full py-2 mt-4 rounded ${
            consents.order && consents.service && consents.privacy
              ? 'bg-[#002060] text-white'
              : 'bg-gray-300 text-gray-500'
          }`}
        >
          동의하고 가입하기
        </button>
      </div>
    </div>
  );
};

export default ConsentForm;
