import { ReactComponent as HeartIcon } from '../../assets/svg/heart.svg';

const Item = ({
  id,
  company,
  thumbnail,
  itemName,
  price,
  materials,
  handleSelectItem,
}) => {
  return (
    <div className='flex gap-4 py-2 px-3'>
      <div
        className='self-start w-1/4'
        onClick={() => handleSelectItem(id)}
      >
        <img
          src={thumbnail}
          alt='thumbnail'
          className='m-0 border border-stone-200 rounded-md'
        />
      </div>
      <div className='flex-1'>
        <div className='text-stone-500'>{company}</div>
        <div
          className='font-bold text-lg'
          onClick={() => handleSelectItem(id)}
        >
          {itemName}
        </div>
        <div>
          {materials.map((v) => (
            <span
              key={v}
              className='text-sm mr-2'
            >
              {v}
            </span>
          ))}
        </div>
      </div>
      <div className='self-start'>
        <HeartIcon />
      </div>
    </div>
  );
};

export default Item;
