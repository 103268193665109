import hanmiImg from '../../../assets/png/hanmi.png';
import jgdImg from '../../../assets/png/jgd.png';
import choaImg from '../../../assets/png/choa.png';
import ildongImg from '../../../assets/png/ildong.png';
import dongaImg from '../../../assets/png/donga.png';
import daewoongImg from '../../../assets/png/daewoong.png';
import boryeongImg from '../../../assets/png/boryeong.png';

const list = [
  {
    icon: hanmiImg,
    label: '한미약품',
    query: '한미약품',
  },
  {
    icon: jgdImg,
    label: '종근당',
    query: '종근당',
  },
  {
    icon: choaImg,
    label: '조아제약',
    query: '조아제약',
  },
  {
    icon: ildongImg,
    label: '일동제약',
    query: '일동제약',
  },
  {
    icon: dongaImg,
    label: '동아제약',
    query: '동아제약',
  },
  {
    icon: daewoongImg,
    label: '대웅제약',
    query: '대웅제약',
  },
  {
    icon: boryeongImg,
    label: '보령제약',
    query: '보령',
  },
];

const SearchTabEfficacy = ({ handlePresetSearchRequest }) => {
  return (
    <div className='grid grid-cols-3 gap-x-4 gap-y-8 mt-4'>
      {list.map((li) => (
        <div
          key={li.label}
          className='flex flex-col items-center gap-2 cursor-pointer'
          onClick={() => handlePresetSearchRequest(li.query)}
        >
          <img
            src={li.icon}
            alt=''
          />
          <label className='font-bold'>{li.label}</label>
        </div>
      ))}
    </div>
  );
};

export default SearchTabEfficacy;
