const InputImgFloating = ({ inputProps, label }) => {
  return (
    <div className="relative z-0">
      <input
        type="file"
        accept="image/*"
        id={`floating_input_${label}`}
        className="block py-2.5 px-0 w-full text-md text-gray-900 bg-transparent border-0 border-b-2 border-[#D9D9D9] appearance-none focus:outline-none focus:ring-0 focus:border-[#002060] peer"
        placeholder=""
        {...inputProps}
      />
      <label
        htmlFor={`floating_input_${label}`}
        className="absolute text-md text-gray-800 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 peer-focus:text-[#002060] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
      >
        {label}
      </label>
    </div>
  );
};

export default InputImgFloating;
