import Item from './item';

const SearchResult = ({ result, resultInfo, handleSelectItem }) => {
  return (
    <div className='max-h-[calc(100svh-54px)] overflow-hidden'>
      <div className='flex justify-between items-center py-2 px-3 mb-2'>
        <div>{resultInfo.totalHits}개의 제품</div>
        <div>추천순</div>
      </div>
      <div className='flex flex-col gap-2 max-h-[calc(100svh-96px)] overflow-auto py-2'>
        {result.map((item, i) => (
          <Item
            key={`${item.itemName}-${i}`}
            handleSelectItem={handleSelectItem}
            {...item}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchResult;
