import { DETAIL_STEP } from '../enum';

export const validateName = (steps, values) => {
  return steps.length === 0 && values.name && values.name.length > 1;
};

export const validatePhoneNumber = (steps, values) => {
  return (
    steps.length === 1 && steps.includes(DETAIL_STEP.NAME) && values.phoneNumber
  );
};

export const validateEmail = (steps, values) => {
  return (
    steps.length === 2 && steps.includes(DETAIL_STEP.PHONE) && values.email
  );
};

export const validatePassword = (steps, values) => {
  return (
    steps.length === 3 && steps.includes(DETAIL_STEP.EMAIL) && values.password
  );
};

export const validateRePassword = (steps, values) => {
  return (
    steps.length === 4 &&
    steps.includes(DETAIL_STEP.PASSWORD) &&
    values.rePassword === values.password
  );
};
