export const SEARCH_TAB = {
  ALL: 'ALL',
  EFFICACY: 'EFFICACY',
  COMPANY: 'COMPANY',
  TERM: 'TERM',
};

export const SEARCH_VIEW = {
  SEARCH: 'SEARCH',
  RESULT: 'RESULT',
  DETAIL: 'DETAIL',
};
