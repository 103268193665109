import defaultProfile from '../../assets/png/profile.png';

const MessageItem = ({ message }) => {
  return (
    <div className='flex items-center justify-between p-4 bg-white rounded-lg shadow'>
      <div className='flex items-center'>
        <img
          src={message.avatar}
          alt='Avatar'
          className='w-12 h-12 rounded-full mr-3'
        />
        <div>
          <div className='text-sm font-bold'>
            {message.company}{' '}
            <span className='text-gray-500'>{message.name}</span>
          </div>
          <div className='text-sm text-gray-500'>{message.message}</div>
        </div>
      </div>
      <div className='text-gray-400 text-xs'>{message.time}</div>
    </div>
  );
};

const MessengerNavPage = () => {
  const messages = [
    {
      id: 1,
      name: '신정아 과장',
      company: '보령제약',
      message: '명절 잘 보내시고 새해 복 많이 받으세요',
      time: '오후 2:00',
      avatar: defaultProfile,
    },
    {
      id: 2,
      name: '김아조 소장',
      company: '조아제약',
      message: '명절 잘 보내시고 새해 복 많이 받으세요',
      time: '어제',
      avatar: defaultProfile,
    },
    {
      id: 3,
      name: '김대호 대리',
      company: '한미약품',
      message: '명절 잘 보내시고 새해 복 많이 받으세요',
      time: '24.01.0',
      avatar: defaultProfile,
    },
  ];

  return (
    <div className='p-4'>
      <h1 className='text-xl font-bold mb-4'>메신저</h1>
      <div className='bg-gray-100 h-20 mb-4 flex items-center justify-center'>
        광고영역
      </div>
      <div className='space-y-4'>
        {messages.map((msg) => (
          <MessageItem
            key={msg.id}
            message={msg}
          />
        ))}
      </div>
    </div>
  );
};

export default MessengerNavPage;
