import { useEffect, useState } from 'react';
import { ReactComponent as SearchSvg } from '../../../assets/svg/search.svg';
import { ReactComponent as ChevronDownSvg } from '../../../assets/svg/arrow-down.svg';
import { presetDateRange } from '../../../lib/util';
import request from '../../../lib/request';
import TransactionItem from './transactionItem';
import PageHeader from '../../../components/page/header';
import FilterBottomSheet from './filterBottomSheet';

const filter = {
  range: {
    oneMonth: '1개월',
    threeMonth: '3개월',
    lastMonth: '지난달',
    customMonth: '직접입력',
  },
  order: { asc: '최신순', desc: '과거순' },
  type: { all: '전체', buy: '사입만', pay: '결제만', refund: '반품만' },
};
const totalGroup = [
  { name: '사업 (원)', key: 'buy' },
  { name: '반품 (원)', key: 'refund' },
  { name: '결제 (원)', key: 'pay' },
];

const Transaction = () => {
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState({ buy: 0, refund: 0, pay: 0 });
  const [openFilter, setOpenFilter] = useState(false);
  const [filterGroup, setFilterGroup] = useState({
    range: 'oneMonth',
    order: 'asc',
    type: 'all',
  });

  useEffect(() => {
    getTransactionList(filterGroup);
  }, [filterGroup]);

  const getTransactionList = async (filter) => {
    const range = presetDateRange(filter.range);
    const order = filter.order;
    const type = filter.type;

    const res = await request.get(
      `/transactions?startDate=${range[0]}&endDate=${range[1]}&order=${order}&type=${type}`
    );
    setItems(res.list);
    setTotal(res.total);
  };

  const handleOpenFilter = () => {
    setOpenFilter((prev) => !prev);
  };

  return (
    <div className='h-dvh overflow-hidden'>
      <PageHeader
        title='거래내역 보기'
        backPath='/mypage'
      />
      <div className='h-[calc(100%-112px)] overflow-y-auto'>
        <div className='flex justify-between px-4 mb-3'>
          <div>
            <SearchSvg />
          </div>
          <div
            className='flex gap-2 cursor-pointer'
            onClick={handleOpenFilter}
          >
            {filter.range[filterGroup.range]} • {filter.type[filterGroup.type]}{' '}
            • {filter.order[filterGroup.order]}
            <ChevronDownSvg />
          </div>
        </div>
        <div className='bg-gray-100 px-6 py-4'>
          <div className='bg-white rounded-md p-4 flex justify-between items-center'>
            {totalGroup.map(({ name, key }) => (
              <div
                key={key}
                className='text-center space-y-1'
              >
                <div className='text-xs text-gray-400'>{name}</div>
                <div className='text-sm font-bold'>
                  {total[key].toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='px-4 divide-y divide-solid divide-gray-100'>
          {items.map((item) => (
            <TransactionItem
              key={item.created_at}
              date={item.transaction_date}
              status={item.type}
              name={item.ledger_item_name}
              company={item.company_name}
              price={item.price}
            />
          ))}
        </div>
      </div>
      {openFilter && (
        <FilterBottomSheet
          handleOpenFilter={handleOpenFilter}
          filterGroup={filterGroup}
          setFilterGroup={setFilterGroup}
        />
      )}
    </div>
  );
};

export default Transaction;
