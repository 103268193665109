import ImgItem from './imgItem';
import request from '../../lib/request';

const PharmacyImgUpload = ({ formData, setFormData }) => {
  const uploadFile = async (key, file) => {
    const filename = file.name;
    const contentType = file.type;

    try {
      // Step 1: Get the pre-signed URL from your server
      const { result } = await request.get(
        `/presigned-url?filename=${filename}&contentType=${contentType}`
      );

      if (!result.url) {
        console.log('Failed to get the pre-signed URL');
        return;
      }
      // Step 2: Upload the file to S3 using the pre-signed URL
      const uploadResponse = await fetch(result.url, {
        method: 'PUT',
        headers: {
          'Content-Type': contentType,
        },
        body: file,
      });

      if (uploadResponse.ok) {
        console.log('File uploaded successfully');
        setFormData({
          ...formData,
          [key]: `https://public-file.pharm-chat.com/${result.uploadPathName}`,
        });
      } else {
        console.log('Failed to upload file');
      }
    } catch (error) {
      console.error('Error:', error);
      console.log('An error occurred');
    }
  };

  const handleFileChange = (key) => (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadFile(key, file);
    }
  };

  return (
    <>
      <ImgItem
        onChange={handleFileChange('pharmacistLicenseImg')}
        label="01 | 약사 면허증"
      />
      <ImgItem
        onChange={handleFileChange('bizRegImg')}
        label="02 | 사업자등록증"
      />
      <ImgItem
        onChange={handleFileChange('pharmacyRegImg')}
        label="03 | 약국 개설 등록증"
      />
    </>
  );
};

export default PharmacyImgUpload;
