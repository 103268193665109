import digestiveImg from '../../../assets/png/digestive.png';
import liverImg from '../../../assets/png/liver.png';
import mouthImg from '../../../assets/png/mouth.png';
import sleepImg from '../../../assets/png/sleep.png';
import nutrientsImg from '../../../assets/png/nutrients.png';
import vitaminImg from '../../../assets/png/vitamin.png';
import heartImg from '../../../assets/png/heart.png';

const list = [
  {
    icon: digestiveImg,
    label: '소화기관제',
    query: '소화제',
  },
  {
    icon: liverImg,
    label: '간기능 개선제',
    query: '간기능',
  },
  {
    icon: heartImg,
    label: '순환계용약',
    query: '순환',
  },
  {
    icon: mouthImg,
    label: '구내염 치료제',
    query: '구내염',
  },
  {
    icon: sleepImg,
    label: '수면 유도제',
    query: '수면',
  },
  {
    icon: vitaminImg,
    label: '건강기능식품',
    query: '건강기능',
  },
  {
    icon: nutrientsImg,
    label: '영양제',
    query: '영양제',
  },
];

const SearchTabEfficacy = ({ handlePresetSearchRequest }) => {
  return (
    <div className='grid grid-cols-3 gap-x-4 gap-y-8 mt-4'>
      {list.map((li) => (
        <div
          key={li.label}
          className='flex flex-col items-center gap-2 cursor-pointer'
          onClick={() => handlePresetSearchRequest(li.query)}
        >
          <img
            src={li.icon}
            alt=''
          />
          <label className='font-bold'>{li.label}</label>
        </div>
      ))}
    </div>
  );
};

export default SearchTabEfficacy;
