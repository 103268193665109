import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from '../../assets/svg/arrow_left.svg';
import InputTextFloating from '../../components/input/textFloating';

const SignInPage = () => {
  const { signIn } = useOutletContext();

  const [saveId, setSaveId] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (window && typeof window === 'object') {
      const savedId = localStorage.getItem('_pharmchatId');
      if (savedId) {
        setEmail(savedId);
        setSaveId(true);
      }
    }
  }, []);

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordInput = (e) => {
    setPassword(e.target.value);
  };

  const handleEnter = async (e) => {
    if (e.key === 'Enter') {
      await handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (!email || !password) {
      return alert('이메일 또는 비밀번호를 입력해주세요.');
    }
    if (saveId) {
      localStorage.setItem('_pharmchatId', email);
    } else {
      localStorage.removeItem('_pharmchatId');
    }
    await signIn(email, password);
  };

  const handleSaveId = () => {
    setSaveId((prev) => !prev);
  };

  return (
    <div className='flex flex-col justify-between h-svh'>
      <div>
        <div className='relative text-center py-3'>
          <div className='font-bold'>로그인</div>
          <div
            className='absolute top-3 left-4'
            onClick={() => {
              window.location.href = '/main';
            }}
          >
            <ChevronLeft />
          </div>
        </div>
        <div className='flex flex-col gap-8 px-4'>
          <InputTextFloating
            inputProps={{
              value: email,
              onChange: handleEmailInput,
              autoFocus: true,
            }}
            label='이메일주소'
          />
          <InputTextFloating
            type='password'
            inputProps={{
              value: password,
              onChange: handlePasswordInput,
              onKeyUp: handleEnter,
            }}
            label='비밀번호'
          />
          <div className='flex justify-between'>
            <div className='flex gap-2'>
              <input
                type='checkbox'
                className=''
                checked={saveId}
                onChange={handleSaveId}
              />
              <label>아이디 저장</label>
            </div>
            <div className='flex gap-2'>
              <a href='/home'>아이디/비밀번호 찾기</a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button
          className='w-full py-3 border border-[#002060] bg-[#002060] text-white'
          onClick={handleSubmit}
        >
          로그인
        </button>
      </div>
    </div>
  );
};

export default SignInPage;
