// import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import NavBottom from '../components/nav/bottom';
import useAuth from '../hook/useAuth';

const Layout = () => {
  const { loading, user, signIn, signOut } = useAuth();

  return (
    <div className='max-w-lg mx-auto'>
      <Outlet context={{ loading, user, signIn, signOut }} />
      <NavBottom />
    </div>
  );
};

export default Layout;
