import { PHARMACY_STEP } from '../pharmacyForm';

export const validatePharmacyName = (steps, values) => {
  return steps.length === 0 && values.pharmacyName;
};

export const validateAddress = (steps, values) => {
  return (
    steps.length === 1 &&
    steps.includes(PHARMACY_STEP.PHARMACY_NAME) &&
    values.address &&
    values.addressDetail
  );
};

export const validateHIRA = (steps, values) => {
  return (
    steps.length === 2 &&
    steps.includes(PHARMACY_STEP.ADDRESS) &&
    values.hiraRegNo
  );
};

export const validatePharmacistRegNo = (steps, values) => {
  return (
    steps.length === 3 &&
    steps.includes(PHARMACY_STEP.HIRA_REG_NO) &&
    values.pharmacistRegNo
  );
};

export const validateRegistrationImg = (steps, values) => {
  return (
    steps.length === 4 &&
    steps.includes(PHARMACY_STEP.PHARMACIST_REG_NO) &&
    values.pharmacistLicenseImg &&
    values.bizRegImg &&
    values.pharmacyRegImg
  );
};
