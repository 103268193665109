import logo from '../../assets/svg/logo.svg';
import entranceImg from '../../assets/png/enterance.png';

const EntrancePage = () => {
  return (
    <div className='h-screen flex flex-col justify-between pb-2'>
      <div className='text-center'>
        <div className='text-right py-5 px-4 text-[#BFBFBF] font-semibold'>
          <div>계정찾기</div>
        </div>
        <div className='w-32 mx-auto mb-6'>
          <img
            src={logo}
            alt=''
          />
        </div>
        <div className='text-2xl font-bold'>
          일반의약품 관리,
          <br />
          하나로 끝
        </div>
        <div className='flex flex-col gap-2 text-center mt-14 w-64 mx-auto'>
          <button
            className='border border-[#002060] bg-[#002060] text-white rounded-md py-3 font-semibold'
            onClick={() => {
              window.location.href = '/main/signin';
            }}
          >
            기존 회원 로그인
          </button>
          <button
            className='border border-[#002060] rounded-md py-3 px-20 text-[#002060] font-semibold'
            onClick={() => {
              window.location.href = '/main/signup';
            }}
          >
            회원가입
          </button>
        </div>
      </div>
      <div className='w-full'>
        <img
          src={entranceImg}
          alt=''
          className='w-full'
        />
      </div>
    </div>
  );
};

export default EntrancePage;
