import { useEffect, useState } from 'react';

const filterGroupList = [
  {
    name: '조회기간',
    group: 'range',
    options: [
      { label: '1개월', value: 'oneMonth' },
      { label: '3개월', value: 'threeMonth' },
      { label: '지난달', value: 'lastMonth' },
      { label: '직접입력', value: 'customMonth' },
    ],
  },
  {
    name: '거래유형',
    group: 'type',
    options: [
      { label: '전체', value: 'all' },
      { label: '결제만', value: 'pay' },
      { label: '사입만', value: 'buy' },
      { label: '반품만', value: 'refund' },
    ],
  },
  {
    name: '내역정렬',
    group: 'order',
    options: [
      { label: '최신순', value: 'asc' },
      { label: '과거순', value: 'desc' },
    ],
  },
];

// const typeFilterGroup = {
//   name: '분류',
//   options: [
//     { label: '전체', value: '1' },
//     { label: '전문의약품', value: '2' },
//     { label: '일반의약품', value: '3' },
//     { label: '의약외품', value: '4' },
//     { label: '한약(생약)제제', value: '5' },
//     { label: '건강(기능)식품', value: '6' },
//   ],
// };

const FilterGroup = ({
  group,
  name,
  options,
  tempFilterGroup,
  handleTempFilterGroup,
}) => {
  return (
    <div>
      <div className='mb-2'>{name}</div>
      <div className='flex border divide-x'>
        {options.map(({ label, value }) => (
          <div
            key={value}
            className={`flex-1 py-1 ${
              tempFilterGroup[group] === value ? 'white' : 'bg-gray-100'
            } `}
            onClick={handleTempFilterGroup(group, value)}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
};

const FilterBottomSheet = ({
  handleOpenFilter,
  filterGroup,
  setFilterGroup,
}) => {
  const [tempFilterGroup, setTempFilterGroup] = useState({
    range: 'oneMonth',
    order: 'asc',
    type: 'all',
  });

  useEffect(() => {
    setTempFilterGroup(filterGroup);

    return () => {
      setTempFilterGroup({
        range: 'oneMonth',
        order: 'asc',
        type: 'all',
      });
    };
  }, [filterGroup]);

  const handleTempFilterGroup = (key, value) => () => {
    setTempFilterGroup((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleConfirm = () => {
    setFilterGroup(tempFilterGroup);
    handleOpenFilter();
  };

  return (
    <div className='absolute bottom-0 w-full min-h-80 bg-white rounded-t-xl z-10 flex flex-col justify-between items-center text-center'>
      <div className='w-full px-8 py-4 flex-1 flex flex-col justify-between text-sm '>
        {filterGroupList.map(({ group, name, options }) => (
          <FilterGroup
            key={group}
            group={group}
            name={name}
            options={options}
            tempFilterGroup={tempFilterGroup}
            handleTempFilterGroup={handleTempFilterGroup}
          />
        ))}
      </div>
      <button
        className='w-full py-2 text-white bg-[#002060]'
        onClick={handleConfirm}
      >
        확인
      </button>
    </div>
  );
};

export default FilterBottomSheet;
