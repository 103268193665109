import { SEARCH_TAB } from './enum';
import SearchTabAll from '../../components/searchTab/all';
import SearchTabEfficacy from '../../components/searchTab/efficacy';
import SearchTabCompany from '../../components/searchTab/company';
import SearchTabTerm from '../../components/searchTab/term';

const SearchTab = ({
  searchTab,
  handleSearchTab,
  handlePresetSearchRequest,
}) => {
  return (
    <div>
      <ul className='flex text-center text-stone-400 font-bold'>
        <li
          className={`border-b flex-1 pb-2 ${
            searchTab === SEARCH_TAB.ALL
              ? 'border-black text-black'
              : 'border-stone-400'
          }`}
          onClick={handleSearchTab(SEARCH_TAB.ALL)}
        >
          전체
        </li>
        <li
          className={`border-b flex-1 pb-2 ${
            searchTab === SEARCH_TAB.EFFICACY
              ? 'border-black text-black'
              : 'border-stone-400'
          }`}
          onClick={handleSearchTab(SEARCH_TAB.EFFICACY)}
        >
          효능군
        </li>
        <li
          className={`border-b flex-1 pb-2 ${
            searchTab === SEARCH_TAB.COMPANY
              ? 'border-black text-black'
              : 'border-stone-400'
          }`}
          onClick={handleSearchTab(SEARCH_TAB.COMPANY)}
        >
          회사명
        </li>
        <li
          className={`border-b flex-1 pb-2 ${
            searchTab === SEARCH_TAB.TERM
              ? 'border-black text-black'
              : 'border-stone-400'
          }`}
          onClick={handleSearchTab(SEARCH_TAB.TERM)}
        >
          용어명
        </li>
      </ul>
      {searchTab === SEARCH_TAB.ALL && (
        <SearchTabAll handlePresetSearchRequest={handlePresetSearchRequest} />
      )}
      {searchTab === SEARCH_TAB.EFFICACY && (
        <SearchTabEfficacy
          handlePresetSearchRequest={handlePresetSearchRequest}
        />
      )}
      {searchTab === SEARCH_TAB.COMPANY && (
        <SearchTabCompany
          handlePresetSearchRequest={handlePresetSearchRequest}
        />
      )}
      {searchTab === SEARCH_TAB.TERM && (
        <SearchTabTerm handlePresetSearchRequest={handlePresetSearchRequest} />
      )}
    </div>
  );
};

export default SearchTab;
