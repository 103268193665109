import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import defaultProfile from '../../assets/png/profile.png';
import cameraCircle from '../../assets/png/camera-circle.png';
import PageHeader from '../../components/page/header';
import InputTextFloating from '../../components/input/textFloating';

const USER_TYPE = {
  OWNER_PHARMACIST: '개국약사',
  SALES: '제약사 직원',
  STUDENT: '약대생',
  PAY_PHARMACIST: '근무 약사',
};

const AccountManagement = () => {
  const { loading, user, signOut } = useOutletContext();

  useEffect(() => {
    if (!loading && (!user || (user && !user.email))) {
      window.location.href = '/main';
    }
  }, [user, loading]);

  return (
    <div className='h-dvh overflow-hidden'>
      <PageHeader
        title='계정관리'
        backPath='/mypage'
      />
      <div className='px-4 pt-4 pb-4 h-[calc(100%-112px)] overflow-y-auto'>
        <section className='mb-8'>
          <h2 className='text-gray-400 mb-2'>회원정보</h2>
          <div className='flex justify-center items-center mb-4'>
            <div className='relative w-16 h-16 rounded-full flex items-center justify-center'>
              <img
                src={defaultProfile}
                alt='Profile'
                className='w-full'
              />
              <img
                src={cameraCircle}
                alt='camera'
                className='absolute bottom-0 right-0 rounded-full'
              />
            </div>
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='이름'
              inputProps={{
                value: user ? user.name : '',
              }}
            />
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='가입유형'
              inputProps={{
                value: user ? USER_TYPE[user.userType] : '',
              }}
            />
            <button className='border border-gray-300 px-4 py-2 rounded'>
              가입유형 변경
            </button>
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='연락처'
              inputProps={{
                value: user ? user.phoneNumber : '',
              }}
            />
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='아이디(E-mail)'
              inputProps={{
                value: user ? user.email : '',
              }}
            />
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='비밀번호'
              inputProps={{
                value: '*******',
              }}
            />
            <button className='border border-gray-300 px-4 py-2 rounded'>
              변경
            </button>
          </div>
          <div className='mb-4'>
            <label className='block text-sm font-bold'>약사번호</label>
            <p className='text-lg'>000000000</p>
          </div>
        </section>
        <section className='mb-8'>
          <h2 className='text-gray-400 mb-2'>약국정보</h2>
          <div className='mb-4'>
            <InputTextFloating
              label='약국이름'
              inputProps={{
                value: user ? user.pharmacies[0].name : '',
              }}
            />
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='약국 주소'
              inputProps={{
                value:
                  '서울특별시 강남구 강남대로 396 강남역지하상가 E52-1(10번11번출구사이)',
              }}
            />
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='약국전화번호'
              inputProps={{
                value: '02)2222-3333',
              }}
            />
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='사업자번호'
              inputProps={{
                value: '123-12-12412',
              }}
            />
          </div>
          <div className='mb-4'>
            <InputTextFloating
              label='요양기관번호'
              inputProps={{
                value: '123-12-12412',
              }}
            />
          </div>
          <div className='mb-4'>
            <label className='block text-sm font-bold'>사업자 등록증</label>
            <img
              src='https://via.placeholder.com/150'
              alt='Business Registration'
              className='mt-2 rounded border'
            />
          </div>
        </section>
        <div className='flex justify-center gap-4'>
          <button className='text-blue-500'>회원탈퇴</button>
          <span>or</span>
          <button
            className='text-blue-500'
            onClick={signOut}
          >
            로그아웃
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountManagement;
