export const USER_TYPE = {
  OWNER_PHARMACIST: 'OWNER_PHARMACIST',
  SALES: 'SALES',
  STUDENT: 'STUDENT',
  PAY_PHARMACIST: 'PAY_PHARMACIST',
};

export const DETAIL_STEP = {
  NAME: 'NAME',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  RE_PASSWORD: 'RE_PASSWORD',
};

export const SIGNUP_STEP = {
  USER_TYPE: 'USER_TYPE',
  CONSENT: 'CONSENT',
  DETAIL: 'DETAIL',
  PHARMACY: 'PHARMACY',
  COMPANY: '',
};
