import { useState } from 'react';
import { SIGNUP_STEP } from '../enum';
import {
  validateName,
  validatePhoneNumber,
  validateEmail,
  validatePassword,
  validateRePassword,
} from '../validation/detail';
import request from '../../../lib/request';
import DetailItem from '../detailItem';
import { DETAIL_STEP } from '../enum';

const DetailForm = ({ formData, setFormData, setPharmacy, nextStep }) => {
  const [detailSteps, setDetailSteps] = useState([]);
  const [heading, setHeading] = useState('회사를 선택해주세요');

  const nextDetailSteps = () => {
    const filledIn = detailSteps.slice();
    if (validateRePassword(detailSteps, formData)) {
      filledIn.push(DETAIL_STEP.RE_PASSWORD);
    }
    if (validatePassword(detailSteps, formData)) {
      filledIn.push(DETAIL_STEP.PASSWORD);
      setHeading('비밀번호를 한번 더 확인해주세요');
    }
    if (validateEmail(detailSteps, formData)) {
      filledIn.push(DETAIL_STEP.EMAIL);
      setHeading('사용할 비밀번호를 입력해주세요');
    }
    if (validatePhoneNumber(detailSteps, formData)) {
      filledIn.push(DETAIL_STEP.PHONE);
      setHeading('ID로 사용할 이메일을 입력해주세요');
    }
    if (validateName(detailSteps, formData)) {
      filledIn.push(DETAIL_STEP.NAME);
      setHeading('휴대폰 번호를 입력해주세요');
    }
    setDetailSteps(filledIn);
  };

  const handleOnChange = (key) => (e) => {
    setFormData({
      ...formData,
      [key]: String(e.target.value),
    });
  };

  const handleEnter = (key) => (e) => {
    if (e.key === 'Enter') {
      handleOnChange(key)(e);
      nextDetailSteps();
    }
  };

  const handleSubmit = async () => {
    console.log('Detail formData: ', formData);

    try {
      const { result } = await request.post('/signup', {
        ...formData,
        step: 'DETAIL',
      });

      if (result?.id) {
        setPharmacy((prev) => {
          return {
            ...prev,
            userId: result.id,
          };
        });
      }
    } catch (error) {
      console.error('Error:', error);
      console.log('An error occurred');
    }

    nextStep(SIGNUP_STEP.PHARMACY);
  };

  return (
    <div
      className={`flex flex-col justify-between h-full w-full ${
        detailSteps.includes(DETAIL_STEP.RE_PASSWORD)
          ? 'backdrop-contrast-50 overflow-hidden'
          : 'overflow-y-scroll'
      }`}
    >
      <div className='p-4'>
        <h1 className='text-xl font-bold mb-12'>{heading}</h1>
        {detailSteps.includes(DETAIL_STEP.PASSWORD) && (
          <DetailItem
            type='password'
            value={formData.rePassword}
            onChange={handleOnChange('rePassword')}
            onKeyUp={handleEnter('rePassword')}
            label='비밀번호 재입력'
          />
        )}
        {detailSteps.includes(DETAIL_STEP.EMAIL) && (
          <DetailItem
            type='password'
            value={formData.password}
            onChange={handleOnChange('password')}
            onKeyUp={handleEnter('password')}
            label='비밀번호 입력'
          />
        )}
        {detailSteps.includes(DETAIL_STEP.PHONE) && (
          <DetailItem
            type='email'
            value={formData.email}
            onChange={handleOnChange('email')}
            onKeyUp={handleEnter('email')}
            label='이메일주소'
          />
        )}
        {detailSteps.includes(DETAIL_STEP.NAME) && (
          <DetailItem
            value={formData.phoneNumber}
            onChange={handleOnChange('phoneNumber')}
            onKeyUp={handleEnter('phoneNumber')}
            label='휴대폰 번호'
          />
        )}
        <DetailItem
          value={formData.name}
          onChange={handleOnChange('name')}
          onKeyUp={handleEnter('name')}
          label='선택하세요'
        />
      </div>
      {detailSteps.includes(DETAIL_STEP.RE_PASSWORD) && (
        <div className='fixed bottom-0 w-full h-80 bg-white rounded-t-xl'>
          <div className='h-full flex justify-center items-center text-center'>
            <div>
              <p className='text-5xl'>💙</p>
              <p className='text-xl font-bold'>가입 예약을 완료했어요</p>
              <p>이어서 약국 정보를 입력해주세요.</p>
            </div>
          </div>
        </div>
      )}
      <div className='absolute bottom-0 w-full'>
        <button
          onClick={
            detailSteps.includes(DETAIL_STEP.RE_PASSWORD)
              ? handleSubmit
              : nextDetailSteps
          }
          className='w-full py-2 mt-4 rounded text-white bg-[#002060]'
        >
          {detailSteps.includes(DETAIL_STEP.RE_PASSWORD)
            ? '약국 정보 입력'
            : '다음'}
        </button>
      </div>
    </div>
  );
};

export default DetailForm;
