import InputImgFloating from '../../components/input/imgFloating';

const ImgItem = ({ onChange, label }) => {
  return (
    <div className="mb-12">
      <InputImgFloating
        inputProps={{
          onChange,
        }}
        label={label}
      />
    </div>
  );
};

export default ImgItem;
