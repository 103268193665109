import { useState } from 'react';
import { SEARCH_TAB, SEARCH_VIEW } from './enum';
import { ReactComponent as ChevronLeft } from '../../assets/svg/arrow_left.svg';
import { ReactComponent as HeartIcon } from '../../assets/svg/heart.svg';
import request from '../../lib/request';
import SearchTab from './tab';
import SearchResult from './result';
import SearchDetail from './detail';

const SearchPage = () => {
  const [searchTab, setSearchTab] = useState(SEARCH_TAB.ALL);
  const [searchView, setSearchView] = useState(SEARCH_VIEW.SEARCH);
  const [query, setQuery] = useState('');
  const [itemDetail, setItemDetail] = useState({});
  const [result, setResult] = useState([]);
  const [resultInfo, setResultInfo] = useState({});

  const handleSearchTab = (tab) => () => {
    setSearchTab(tab);
  };

  const handleQueryInput = (e) => {
    setQuery(e.target.value);
  };

  const handleInputEnter = async (e) => {
    if (e.key === 'Enter') {
      await handleQuerySearchRequest(query);
    }
  };

  const handleGoBack = () => {
    if (searchView === SEARCH_VIEW.SEARCH) {
      window.location.href = '/';
    }
    if (searchView === SEARCH_VIEW.DETAIL) {
      setSearchView(SEARCH_VIEW.RESULT);
    }
    if (searchView === SEARCH_VIEW.RESULT) {
      setSearchView(SEARCH_VIEW.SEARCH);
    }
  };

  const handleQuerySearchRequest = async (q) => {
    console.log('search query: ', q);
    try {
      if (q.trim() === '') {
        return;
      }

      if (query !== q) {
        setQuery(q);
      }
      const { result } = await request.get(`/search?q=${query}&page=${1}`);
      console.log('search result: ', result);
      if (result.hits.length) {
        setResult(result.hits);
      }

      setResultInfo({
        hitsPerPage: result.hitsPerPage,
        page: result.page,
        totalPages: result.totalPages,
        totalHits: result.totalHits,
      });
    } catch (err) {
      console.log(err);
    }
    setSearchView(SEARCH_VIEW.RESULT);
  };

  const handlePresetSearchRequest = async (q) => {
    if (q && q.trim() !== '') {
      await handleQuerySearchRequest(q);
    }
  };

  const handleSelectItem = async (id) => {
    try {
      // TODO: get item info
      const selected = result.filter((res) => res.id === id)[0];
      if (selected) {
        setItemDetail(selected);
      }
      setSearchView(SEARCH_VIEW.DETAIL);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className='h-svh overflow-hidden'>
      <div
        className={`flex gap-2 items-center px-2 py-3 ${
          searchView === SEARCH_VIEW.DETAIL ? 'justify-between' : ''
        }`}
      >
        <div onClick={handleGoBack}>
          <ChevronLeft />
        </div>
        {searchView === SEARCH_VIEW.DETAIL ? (
          <>
            <div className='font-bold py-1'>{itemDetail.itemName}</div>
            <div>
              <HeartIcon />
            </div>
          </>
        ) : (
          <input
            type='text'
            className='p-1 placeholder:text-stone-400 w-full'
            placeholder='검색어를 입력하세요'
            value={query}
            onKeyUp={handleInputEnter}
            onChange={handleQueryInput}
          />
        )}
      </div>
      <div className='max-h-[calc(100svh-54px)] overflow-hidden'>
        {searchView === SEARCH_VIEW.SEARCH && (
          <SearchTab
            searchTab={searchTab}
            handleSearchTab={handleSearchTab}
            handlePresetSearchRequest={handlePresetSearchRequest}
          />
        )}
        {searchView === SEARCH_VIEW.RESULT && (
          <SearchResult
            result={result}
            resultInfo={resultInfo}
            handleSelectItem={handleSelectItem}
          />
        )}
        {searchView === SEARCH_VIEW.DETAIL && (
          <SearchDetail itemDetail={itemDetail} />
        )}
      </div>
    </div>
  );
};

export default SearchPage;
