import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';
import { ReactComponent as BellIcon } from '../../assets/svg/bell.svg';

const NavHeader = () => {
  return (
    <nav className='py-3 px-4 fixed top-0 left-0 right-0 bg-white'>
      <ul className='flex items-center justify-between'>
        <li>
          <Link to={''}>
            <Logo
              width='107'
              height='20'
            />
          </Link>
        </li>
        <li>
          <ul className='flex items-center gap-4'>
            <li>
              <Link to={'notification'}>
                <BellIcon />
              </Link>
            </li>
            <li>
              <Link to={'search'}>
                <SearchIcon />
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default NavHeader;
